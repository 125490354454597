import * as R from 'ramda'
import * as React from 'react'
import * as Data from '../data'
import fastDeepEqual from 'fast-deep-equal'
import PropTypes from 'prop-types'

const WidgetName = {
  COMBO_OF_THE_DAY: 'combo_of_the_day',
  EVENT_SCHEDULE: 'events_schedule',  
  LINE_BANNER: 'line_banner',
  PROMO: 'promo',
  PROMO_WITH_SPORTS: 'promo_with_sports',
  TOP_MATCH: 'top_match',
  TOP_OUTRIGHT: 'top_outright',
}

function usePreviousValue(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}
/**
 * Handles launching of Betby sportsbook & widgets
 * @param {Object} props
 * @param {Object} props.config launch configs
 * @param {number=} [props.config.betSlipOffsetBottom = 0] The parameter defines the gap between window bottom and betslip (forexample, fixed footer).
 * @param {number=} [props.config.betSlipOffsetTop = 0] The parameter defines the gap between window top and opened betslip (for example, fixed header).
 * @param {number=} [props.config.betSlipZIndex = 999] The parameter defines the index of betslip in relation to DOM elements used on Partners website. 
 * @param {string} props.config.brandId Unique Partner Website ID received during the onboarding process.
 * @param {string[]=} props.config.cssUrls The parameter defines the links to fonts are used on the Partners website. Is used for fonts only!
 * @param {string[]=} props.config.fontFamilies The parameter defines the name of fonts are used on the Partners website.
 * @param {string=} props.config.key Unique timely-restricted IP-binded onetime key assigned to each player. (Session-token)
 * @param {string=} [props.config.lang = 'en'] Language to launch with. See language support in documentation
 * @param {number=} props.config.minFrameHeight the minimum height to load widget in
 * @param {string} props.config.themeName Unique name of theme provided by Betby.
 * @param {('left' | 'right')=} [props.config.scrollTopButtonPosition = 'left']
 * @param {string=} props.config.widgetName the available values are defined in `WidgetName`
 * @param {string=} props.config.widgetPlaceholder This value must match the placeholder setup in the BO for specific widgets
 * @param {string=} props.nextRoute route used for deeplinking
 * @returns {Component} Launches the sportsbook/widget
 */
export function BetbyLauncher(props) {
  const dispatch = Data.useDispatch()
  const ref = React.useRef(null)
  const [isAppInitialized, setAppInitialized] = React.useState(false)
  const [shouldInitApp, setShouldInitApp] = React.useState(false)
  const prevConfig = usePreviousValue(props.config)
  const [btRendererInited, setBtRendererInited] = React.useState(false)

  React.useEffect(() => {
    if (!btRendererInited) {
      const timerId = window.setInterval(() => {
        setBtRendererInited(
          typeof window.BTRenderer !== 'undefined'
        )
      }, 250)
      return () => {
        clearInterval(timerId)
      }
    }
  }, [btRendererInited])

  const client = React.useMemo(
    () => {
      if (process.browser && btRendererInited) {
        /**
         * Available callback on window.BTRenderer
         * @param {func} goToClick is called from widgets that has internal links
         * @param {func} onAppInitialized Is called when app is initalized 
         * @param {func} onBetslipToggle Is called when betslip is toggled 
         * @param {func} onRouteChange is called when routing changes within the sportsbook
         * @param {func} onLogin is called when a login is requested from the sportsbook
         * @param {func} onRegister is called when a registration is requested from the sportsbook 
         * @param {func} onSessionRefresh is called when iframe needs to be re-initalized with a new key (session-token) 
         * @param {func} onRecharge is called when a deposit is requested from the sportsbook 
         * @param {func} onBannerClick is called when a banner is clicked
         * @returns {Object} Betby Sportsbook
         */
        return new window.BTRenderer()
      }
    },
    [process.browser, btRendererInited]
  )

  // Base configuration for any launch-type
  const baseConfig = React.useMemo(
    () => {
      if (!R.isEmpty(props.config) && ref.current) {
        return {
          brand_id: props.config.brandId,
          cssUrls: props.config.cssUrls,
          fontFamilies: props.config.fontFamilies,
          key: props.config.key,
          lang: props.config.lang,
          themeName: props.config.themeName,
          target: ref.current,
        }
      } else {
        return {}
      }
    },
    [
      props.config.brandId,
      props.config.cssUrls,
      props.config.fontFamilies,
      props.config.key,
      props.config.lang,
      props.config.themeName,
      ref.current
    ]
  )
  
  // Sportsbook specific configuration
  const sportsbookConfig = React.useMemo(
    () => {
      if (!R.isEmpty(props.config)) {
        return {
          betSlipOffsetBottom: props.config.betSlipOffsetBottom,
          betSlipOffsetTop: props.config.betSlipOffsetTop,
          betslipZIndex: props.config.betSlipZIndex,
          scrollTopButtonPosition: props.config.scrollTopButtonPosition 
        }
      } else {
        return {}
      }
    },
    [
      props.config.betSlipOffsetBottom,
      props.config.betSlipOffsetTop,
      props.config.betSlipZIndex,
      props.config.scrollTopButtonPosition
    ]
  )

  // Hook signalize that betby instance has been
  // created and betby is ready to receive config.
  React.useEffect(() => {
    if (client && ref) {
      dispatch({ type: Data.BETBY_INITIATED })
    }
  }, [client, dispatch, ref])

  // Hook for checking if app should be reinited.
  React.useEffect(() => {
    // Do nothing if there is no refrence
    if (ref.current, btRendererInited) {
      // Config has been updated; e.g. language was switched.
      if (!fastDeepEqual(props.config, prevConfig)) {
        client.kill()
        setShouldInitApp(true)
      }
    }
  }, [    
    props.config,
    prevConfig,
    ref,
    setShouldInitApp,
    btRendererInited,
  ])

  // Initiate the app if the minimal data exists
  React.useEffect(() => {
    if (ref.current && props.config.brandId, btRendererInited) {
      setShouldInitApp(true)
    }
  }, [ref.current, props.config.brandId, btRendererInited])

  // Hook responsible for updating page if host app requests it.
  React.useEffect(() => {
    // Updates app route (which is requested from host app) and then reset nextRoute).
    if (client && props.nextRoute && isAppInitialized) {
      client.updateOptions({ url: props.nextRoute })
      dispatch({ type: Data.BETBY_ROUTE_UPDATED, payload: null })
    }
  }, [
    client,
    dispatch,
    props.nextRoute,
    isAppInitialized,
  ])

  // Hook responsible for initing app.
  // Can be a widget or "normal"
  React.useEffect(() => {
    if (shouldInitApp) {
      setShouldInitApp(false)
      
      const baseEventHandlers = {
        onAppInitialized: () => setAppInitialized(true),
        onBetslipToggle: () => dispatch({ type: Data.BETBY_BETSLIP_TOGGLED }),
        onRouteChange: payload => dispatch({ type: Data.BETBY_ROUTE_CHANGED, payload }),
        onSessionRefresh: () => dispatch({ type: Data.BETBY_SESSION_REFRESHED }),
      }

      const widgetEventHandlers = {
        goToClick: payload => dispatch({ type: Data.BETBY_EVENT_LINK_CLICKED, payload }),
        onBannerClick: payload => dispatch({ type: Data.BETBY_BANNER_CLICKED, payload }),
        onLogin: () => dispatch({ type: Data.BETBY_LOGIN_BUTTON_CLICKED }),
        onRecharge: () => dispatch({ type: Data.BETBY_RECHARGED }),
        onRegister: () => dispatch({ type: Data.BETBY_REGISTRATION_BUTTON_CLICKED }),
        onSportClick: () => dispatch({ type: Data.BETBY_SPORTS_CLICKED }),
      }

      switch (props.config.widgetName) {
        case WidgetName.COMBO_OF_THE_DAY: {
          client.initialize({
            ...baseConfig,
            widgetName: WidgetName.COMBO_OF_THE_DAY,
            widgetParams: {
              minFrameHeight: props.config.minFrameHeight,
              placeholder: props.config.widgetPlaceholder,
              withAuthentication: true,
              ...widgetEventHandlers
            },
            ...baseEventHandlers
          })
          break
        }
        case WidgetName.EVENT_SCHEDULE: {
          client.initialize({
            ...baseConfig,
            widgetName: WidgetName.EVENT_SCHEDULE,
            widgetParams: {
              minFrameHeight: props.config.minFrameHeight,
              placeholder: props.config.widgetPlaceholder,
              ...widgetEventHandlers
            },
            ...baseEventHandlers
          })
          break
        }
        case WidgetName.PROMO: {
          client.initialize({
            ...baseConfig,
            widgetName: WidgetName.PROMO,
            widgetParams: {
              minFrameHeight: props.config.minFrameHeight,
              placeholder: props.config.widgetPlaceholder,
              ...widgetEventHandlers
            },
            ...baseEventHandlers
          })
          break
        }
        case WidgetName.PROMO_WITH_SPORTS: {
          client.initialize({
            ...baseConfig,
            widgetName: WidgetName.PROMO_WITH_SPORTS,
            widgetParams: {
              minFrameHeight: props.config.minFrameHeight,
              placeholder: props.config.widgetPlaceholder,                
              withSportsList: true,
              ...widgetEventHandlers
            },
            ...baseEventHandlers
          })
          break
        }
        case WidgetName.TOP_MATCH: {
          client.initialize({
            ...baseConfig,
            widgetName: WidgetName.TOP_MATCH,
            widgetParams: {
              minFrameHeight: props.config.minFrameHeight,
              placeholder: props.config.widgetPlaceholder,
              withAuthentication: true,
              ...widgetEventHandlers
            },
            ...baseEventHandlers
          })
          break
        }
        case WidgetName.TOP_OUTRIGHT: {
          client.initialize({
            ...baseConfig,
            widgetName: WidgetName.TOP_OUTRIGHT,
            widgetParams: {
              minFrameHeight: props.config.minFrameHeight,
              placeholder: props.config.widgetPlaceholder,
              ...widgetEventHandlers
            },
            ...baseEventHandlers
          })
          break
        }
        case WidgetName.LINE_BANNER: {
          client.initialize({
            ...baseConfig,
            widgetName: WidgetName.LINE_BANNER,
            widgetParams: {
              minFrameHeight: props.config.minFrameHeight,
              placeholder: props.config.widgetPlaceholder,
              ...widgetEventHandlers
            },
            ...baseEventHandlers
          })
          break
        }
        default: {
          client.initialize({
            ...baseConfig,
            ...sportsbookConfig,
            ...widgetEventHandlers,
            ...baseEventHandlers,
          })
          break
        }
      }
    }
  }, [
    baseConfig,
    client,
    props.config.widgetName,
    props.config.widgetPlaceholder,
    dispatch,
    sportsbookConfig,
    setShouldInitApp,
    shouldInitApp,
  ])
  
  // Cleanup client on unmount
  React.useEffect(() => {
    return () => {
      if (client && isAppInitialized) { 
        client.kill()
      }
    }
  }, [client, isAppInitialized])

  return (
    <div
      id="betby"
      ref={ref}      
      style={{
        height: '100%',
        width: '100%',
        overflowY: 'auto',
    }} />  
  )
}

BetbyLauncher.propTypes = {
  config: PropTypes.shape({
    betSlipOffsetBottom: PropTypes.number,
    betSlipOffsetTop: PropTypes.number,
    betSlipZIndex: PropTypes.number,
    brandId: PropTypes.string,
    cssUrls: PropTypes.array,
    fontFamilies: PropTypes.array,
    key: PropTypes.string,
    lang: PropTypes.string,
    minFrameHeight: PropTypes.number,
    themeName: PropTypes.string,
    scrollTopButtonPosition: PropTypes.oneOf(['left', 'right']),
    widgetName: PropTypes.string,
    widgetPlaceholder: PropTypes.string,
  }),
  nextRoute: PropTypes.string,
}

// --

function mapState(state) {
  const baseConfig = Data.getBetbyConfig(state)
  const key = Data.getToken(state)
  const lang = Data.getLanguage(state)

  const config = {
    betSlipOffsetBottom: 0,
    betSlipOffsetTop: 0,
    betSlipZIndex: 999,
    scrollTopButtonPosition: 'left',
    ...baseConfig,
    key,
    lang: lang || 'en',
  }

  return {
    config,
    nextRoute: Data.getBetbyNextRoute(state),
  }
}

const connector = Data.connect(mapState)


export default connector(BetbyLauncher)
